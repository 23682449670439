
//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/layouts";

//Components
@import "custom/components/helper";
@import "custom/components/social";
@import "custom/components/widgets";
@import "custom/components/custom-checkbox";
@import "custom/components/custom-radio";
@import "custom/components/ribbons";
@import "custom/components/print";
@import "custom/components/preloader";
@import "custom/components/accordions";

// pages
@import "custom/pages/components-demo";
@import "custom/pages/email";
@import "custom/pages/authentication";
@import "custom/pages/error";
@import "custom/pages/taskboard";
@import "custom/pages/taskdetails";
@import "custom/pages/profile";
@import "custom/pages/pricing";
@import "custom/pages/timeline";
@import "custom/pages/gallery";
@import "custom/pages/coming-soon";

// Vendors
@import "vendor/metisMenu";

// Plugins
@import "custom/plugins/waves";
@import "custom/plugins/slimscroll";
@import "custom/plugins/sweetalert";
@import "custom/plugins/toastr";
@import "custom/plugins/ion-rangeslider";
@import "custom/plugins/bootstrap-tagsinput";
@import "custom/plugins/multiple-select";
@import "custom/plugins/select2";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/colorpicker";
@import "custom/plugins/daterange";
@import "custom/plugins/parsley";
@import "custom/plugins/form-wizard";
@import "custom/plugins/dropify";
@import "custom/plugins/form-editor";
@import "custom/plugins/x-editable";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/tablesaw";
@import "custom/plugins/flot";
@import "custom/plugins/morris";
@import "custom/plugins/chartist";
@import "custom/plugins/chartjs";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/calendar";
@import "custom/plugins/summernote";
@import "custom/plugins/hopscotch";
@import "custom/plugins/jstree";
@import "custom/plugins/google-maps";
@import "custom/plugins/vector-maps";


@font-face {
    font-family: 'tt_normsregular';
    //src: url('TTNorms-Medium.otf') format('woff2');
    src: url('ttnorms-regular-webfont.woff2') format('woff2'),
         url('ttnorms-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-variant-ligatures: no-common-ligatures !important;
}

@media(max-width: 376px) {
    .navbar-custom .button-menu-mobile {
        width: 124px !important;
    }

    .navbar-custom .button-menu-mobile img{
        margin-left: 5px !important;
        height: 16px !important;
        width: 96px !important;
    }
}

@media(min-width: 376px) {
    .navbar-custom .button-menu-mobile {
        width: 200px !important;
    }    
}

@media(max-width: 485px) {
    .small-nav a {
        padding: 20px 26px !important;
    }

    .small-nav img {
        width: 23px;
    }

    .small-nav span {
        font-size: 10px;
    }

    .popover {
        transform: translate3d(0px, -254px, 0px) !important;
    }
}
@media(min-width: 486px) {
    .popover {
        transform: translate3d(-76px, -254px, 0px) !important;
    }    
}

@media(max-width: 576px) {
    .action-header-list{
        white-space: nowrap;
        text-align: start;
        margin-top: 22px;
    }

    .expiration-row {
        height: 100% !important;
    }
}

@media(min-width: 768px) {
    .small-nav {
        display: none;
    }

    .button-menu-mobile {
        display: none;
    }

}

@media(max-width: 867px) {
    .button-menu-mobile.disable-btn {
        display: block !important;
    }
    .content-page {
        margin-left: 0px !important;
    }
    .logo-box {
        display: none;
        background-color: #f6f6f6;
    }
    .small-nav {
        z-index: 99;
        display: block;
    }
    .left-side-menu {
        display: none !important;
    }
    .content-page {
        margin-top: 140px !important;
    }
    .new-del-container.pdf-preview-container {
        max-width: 722px !important;
    }
    .delivery-created-grid {
        position: absolute;
        left: 10px !important;
        text-align: left;
    }
}

@media(min-width: 868px) {
    .logo-box {
        display: block;
        background-color: #f6f6f6;
    }
}
@media(max-width: 1276px) {
    .rc-steps {
        padding: 45px 60px !important;
    }
}

@media(max-width: 1170px) {
    .rc-steps {
        padding: 45px 30px !important;
    }
}

@media(max-width: 992px) {
    .new-del-container.pdf-preview-container {
        max-width: 662px !important;
    }

    .expiration-container {
        width: 100% !important;
    }
}


html *
{
    font-family: 'tt_normsregular', Arial, sans-serif;
}

body {
    font-variant-ligatures: no-common-ligatures !important;
}

.table thead th {
    color: #00BF71;
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.scrollbar-container {
    background-color: #01bf71;
}

.logo-box {
    background-color: #f6f6f6;
}

.navbar-custom {
    background-color: #f6f6f6;
}

.left-side-menu {
    background-color: #01bf71;
}

.status-badge-success {
    font-size: 13px;
    font-weight: 100;
    border-radius: 0px;
    background-color: #00BF71;
    height: 28px;
    width: 85px;
    vertical-align: middle;
}

.status-badge-error {
    font-size: 13px;
    font-weight: 100;
    border-radius: 0px;
    background-color: #E04171;
    height: 28px;
    width: 85px;
    vertical-align: middle;
}

.status-badge-warn {
    font-size: 13px;
    font-weight: 100;
    border-radius: 0px;
    background-color: #F4953D;
    height: 28px;
    width: 85px;
    vertical-align: middle;
}

.input-group-text {
    border-width: 0px;
    background-color: #f2f2f2;

}

.left-side-menu {
    width: 280px;
}

.content-page {
    margin-left: 280px;
}

#sidebar-menu > ul > li > a {
    padding: 14px 25px;
}

#sidebar-menu > ul > li > a:hover {
    background-color: #03A864;
}

.sidebar-list-span {
    font-size: 12px;
}

.custom-button {
    background-color: #f2f2f2;
    color: #787878;
    border-width: 0px;
    border-radius: 6px;
    height: 38px;
}

.custom-form {
    background-color: #f2f2f2;
    border-width: 0px;
    border-radius: 6px;
}

.badge-span-middle {
    vertical-align: sub !important;
}

.left-side-menu-title {
    font-family: 'tt_normsregular', Arial, sans-serif;
    font-weight: 400;
    color: white;
    text-align: center;
    font-size: 30px;
}

.side-nav-link-ref {
    font-family: 'tt_normsregular', Arial, sans-serif;
    color: white !important;
    font-size: 12px !important;
    font-weight: 100 !important;
}

.login-logo {
    height: 70px;
    margin-bottom: 18px;
}

.login-text {
    font-size: 40px;
    color: white;
}

.login-main-col {
    margin-top: 200px;
}

.login-btn {
    background-color: #00BF71;
    border-width: 0px;
    border-radius: 6px;
    height: 44px;
    font-size: 16px;
}

.login-form-label {
    color: white;
    margin-left: 30px;
}

.login-form-input {
    border-width: 0px;
    border-radius: 6px;
    height: 44px;
}


.custom-control-label::before {
    border-color: #00BF71;
    background-color: transparent;
    border-radius: 0px !important;
    border-width: 2px;
}

.custom-control-label {
    color: white;
    margin-left: 6px;
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #00BF71;
    background-color: transparent;
    border-radius: 0px !important;
    border-width: 2px;
}

.list-table::before {
    border-top-color: #00BF71;
}

.new-del-container {
    height: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
}

.new-del-container.pdf-preview-container {
    max-width: 500px;
    min-height: 800px;
}

.new-del-container-header {
    
}

.new-del-container-body {
    height: 100%;
    border-style: solid;
    border-width: 2px;
    border-radius: 4px;
    border-color: #B1B1B1;
}

.new-del-container-body.detail-steps-container {
    border-color: #00BF71;
    overflow: auto;
}

.new-del-container-body:hover {
    border-color: #00BF71;
}

.rec-title {
    color: #00BF71;
    font-size: 14px;
    font-weight: 600;
}

.rec-title span {
    margin-right: 10px;
}

.disabled-input {
    border-width: 0px !important;
}

.new-del-form-input {
    padding-left: 26px;
    background-color: #f2f2f2;
    border-width: 0px;
    border-radius: 4px;
    margin-bottom: 8px;
    color: #727272
}

.new-del-form-input:focus {
    background-color: white;
    border-width: 2px;
    border-color: #00BF71;
    border-radius: 4px;
}

.new-del-form-advise {
    font-size: 9px;
    margin-left: 16px;
}

.react-switch-handle {
    height: 10px !important;
    width: 10px !important;
    background: white !important;
    display: inline-block !important;
    cursor: pointer !important;
    position: absolute !important;
    top: 3px !important;
    outline: 0px !important;
    border: 0px !important;
    border-radius: 2px !important;
}

.react-switch-bg {
    border-radius: 4px !important;
}

.switch-label {
    margin-left: 10px;
    font-size: 12px;
}

.rounded-circle {
    height: auto !important;
    width: auto !important;
}

.dropzone {
    // background-image: url("../images/icons/drag_drop_placeholder.png");
    // background-repeat: no-repeat;
    // background-position: center;
    height: 100%;
    // width: 100%;
}

.dropzone-placeholder{
    position: relative;
    top: 35%;
}

.dropzone-placeholder p{
    width: 100% !important;
    margin: 0px;
    margin-top: 10px !important;
    white-space: nowrap;
}


.dropzone:focus {
    outline: none;
}

.dropzone p{
    height: 90px;
    width: 90px;
}

.dropzone-container {
    width: fit-content;
    height: 100%;
    width: 100%;
}

.dropzone-container:focus {
    outline: none;
}

.dropzone-container p {
    margin-top: 20px;
    color: #B1B1B1;
}

.table-action-button {
    margin-right: 20px;
    cursor: pointer;
}

.rc-steps-item-wait > div.rc-steps-item-container > div.rc-steps-item-content > div.rc-steps-item-title {
    color: #B1B1B1 !important;
    border-color: #B1B1B1 !important;
}

.rc-steps-label-vertical .rc-steps-item-content {
    margin-right: 30px !important;
}

.rc-steps-item-title {
    font-size: 12px !important;
    border-style: solid !important;
    border-radius: 3px !important;
    border-color: #00BF71 !important;
    border-width: 2px !important;
    color: #00BF71 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    white-space: nowrap !important;
}

.rc-steps-item-tail:after {
    height: 2px !important;
}

.rc-steps-item-finish .rc-steps-item-tail:after {
    background-color: #00bf71 !important;
}

.rc-steps {
    padding: 45px 100px;
}

.description-list {
    color: #787878;
    font-weight: 600;
    white-space: nowrap;
    margin-top: 10px;
}

.description-list p {
    margin-bottom: 5px;
}

.delivery-detail-table-container {
    overflow: auto;
    border-style: solid !important;
    border-width: 2px !important;
    border-radius: 4px !important;
    border-color: #B1B1B1;
    background-color: #F6F6F6;
}


.delivery-detail-table th{
    color: #787878  !important;
    text-align: center !important;
    font-size: 12px !important;
}

.delivery-detail-table tr{
    text-align: center !important;
}

.card {
    box-shadow: none !important;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
}



.send-delivery-btn {
    padding: 3px 25px;
    float: right;
    width: auto;
    height: auto;
    background-color: #00BF71;
    border-width: 0px;
    border-radius: 6px;
    font-size: 16px;
    margin-top: 15px;
}

.metismenu .active {
    background-color: #03A864;
}


.spinner-border {
    border: none !important;
    height: 90px!important;
    width: 90px!important;
}

.small-spinner-border {
    border: none !important;
    height: 50px!important;
    width: 50px!important;
}

.pdf-nav-button {
    margin-top: 15px;
    border-radius: 112px;
}
.delivery-created-container{
    border-color: #00BF71;
    border-radius: 6px;
    border-style: solid;
    border-width: 2px;

    color: #00BF71;
    font-weight: 800;
    text-align: center;
    padding: 10px;
 }

 .delivery-created-grid {
    position: absolute;
    top: 50%;
    left: 50%;
 }

 .delivery-created-action-button-l {
     margin-top: 10px;
     border-radius: 6px;
     border-width: 0px;
    background-color: #00BF71;
    white-space: nowrap;
 }

 .delivery-created-action-button-r {
    margin-top: 10px;

    border-radius: 6px;
    border-width: 0px;
    background-color: #B1B1B1;
    white-space: nowrap;
 }

 .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

.modal-backdrop.show {
    opacity: 0.9;
}

 .modal-content {
    border-radius: 6px;
    text-align: center;
 }
 .modal-backdrop {
     background-color: #434342;
 }

 .delete-modal-message {
    color: #787878;
    font-weight: 600;
    text-align: center;
    padding: 25px;
 }

 .delete-modal-btn-l {
    border-radius: 6px;
    border-width: 0px;
    width: 110px;
    background-color: #787878;
 }

 .delete-modal-btn-r {
    border-radius: 6px;
    border-width: 0px;
    width: 110px;
    background-color: #00BF71;
}

.react-pdf__Page__textContent {
    display: none !important;
}

.pdf-nav-header {
    color: #727272;
    font-weight: 800;
}

.pdf-nav-btn {
    cursor: pointer;
}

.left-side-menu-separator {
    background-color: white;
    height: 2px;
}

.reminder-container {
    background: #F2F2F2;
    text-align: center;
}

.reminder-row {
    padding-top: 10px;
    padding-bottom: 4px
}

.expiration-container {
    width: fit-content;
    background: #F2F2F2;
    text-align: center;
    border-style: solid;
    border-radius: 4px;
    border-color: #F2F2F2;
}

.expiration-row {
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: 600;
    height: 33px;
}

.expiration-row.row {
    margin-right: 12px;
}

.expiration-row span {
    margin-left: 22px;
    display: table;
}
.expiration-row input {
    margin-left: 10px;
    margin-right: 14px;
}

.expiration-row img {
    display: table-cell;
}

.expiration-row .rdp-input-group {
    width: 115px;
    display: table-cell !important;
}
.rdp-input-group input {
    font-size: 12px;
    text-align: center;
    height: 24px;
    padding: 0px 0px;
    border-radius: 2px;
    border-color: #727272;
    width: 92px !important;
    border-width: 2px;
    font-weight: 600;
    color: #F2F2F2;
    background-color: #727272;
}

.rdp-addon {
    display: none;
}

.popover-header span {
    display: contents;
}


.action-1-btn:hover {
    background-image: "../assets/images/icons/action_2.png";
}

.invalid-login-container {
    border-style: solid;
    background-color: #FFFFFF;
    border-width: 2px;
    border-color: #E04171;
    border-radius: 4px;
    margin-bottom: 14px;
}

.invalid-login-container p {
    text-align: center;
    margin-bottom: 0px;
    padding: 10px 0px;
    color: #E04171;
    font-weight: 900;
}

.reminder-input-select {
    display: inline;
    width: 110px;
    margin-left: 10px;
    background-color: #727272;
    color: #FFFFFF;
    height: 28px;
    padding: 0px 10px;
}

.frequency-input-text {
    display: inline;
    width: 50px;
    padding: 4px;
    border-color: #B1B1B1 !important;
    border-style: solid !important;
    border-width: 2px;
    margin-left: 10px;
    margin-right: 10px;
    height: 28px;
}


.reminder-input-text {
    display: inline;
    width: 110px;
    border-color: #B1B1B1 !important;
    border-style: solid !important;
    border-width: 2px;
    margin-left: 10px;
    height: 28px;
}

.reminder-row img {
    margin-right: 10px;
}


.reminder-row {
    font-weight: 600;
}

.action-header-detail {
    text-align: end;
    margin-top: 70px;
}

.action-header-detail span {
    font-weight: 600;
    color: #787878;
    margin-right: 16px;
    cursor: pointer;
}

.action-header-detail img {
    margin-right: 10px;
}

.action-header-list {
    white-space: nowrap;
    text-align: end;
}


.action-header-list span {
    font-weight: 600;
    color: #787878;
    margin-right: 24px;
    cursor: pointer;
}

.action-header-list img {
    margin-right: 10px;
}

.remove-pdf-preview {
    left: 10px;
    position: absolute;
    cursor: pointer;
}

.custom-checkbox-list {
    margin: 0px 0px !important;
width: 10px;
height: 10px;
}

.custom-checkbox-list .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #00BF71;
}

.table-batch-actions {
    padding-bottom: 10px;
    border-bottom-width: 1px;
    border-bottom-color: #00BF71;
    border-bottom-style: solid;
}

.table-batch-actions label {
    display: inline;
    padding-left: 0.85rem !important;
    margin-left: 26px !important;
    margin-right: 10px !important;
}

.toast-bottom-right {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 15px;
}

.preloader {
    position: fixed;
}

.tooltip {
    position: relative;
    display: inline-block;
    opacity: 1;
    z-index: 2;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 4px 6px;
    position: absolute;
    z-index: 2;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 500ms;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .cLiCmx div:first-child {
      white-space: normal !important;
  }

  .invalid-form {
      border-color: #E04171 !important;
      border-width: 2px;
  }

.form-errors-container {
    margin-top: 18px;
    margin-left: 24px;
    margin-right: 24px;
    padding: 15px 20px;
    border-style: solid;
    border-width: 2px;
    border-radius: 4px;
    border-color: #E04171;
}


.form-errors-container p {
    color: #E04171;
    font-weight: 700;
    margin-bottom: 0px;
}

.custom-toast {
    border-radius: 6px;
    min-width: 280px;
    z-index: 9999;
}

.custom-toast .toast-header {
    padding: 6px 12px;
    background-color: #FFFFFF;
    border-bottom-width: 0px;
    color: #00BF71;
}

.custom-toast .toast-body {
    background-color: #F2F2F2;
    text-align: center;
}

.custom-toast img {
    margin-right: 10px;
}

.rdt_Pagination {
    display: none !important;
}

.search-input-group span {
    padding-right: 2px;
}

.search-input-group img {
    width: 22px;
}

.search-input {
    color: #C5C5C5;
    font-weight: 600;
}

.search-input:focus {
    background-color: #F2F2F2 !important;
}

.nav-user img {
    height: auto;
    width: auto;
}

.language-selector {
    border-style: solid;
    border-radius: 6px;
    border-width: 2px;
    border-color: #727272;
    margin-top: 24px;
}

.language-selector span{
    display: flex;
}

.language-selector img{
    height: 12px;
    width: 10px;
    margin: 6px;
}

.language-selector select {
    height: 24px;
    padding: 0px 0px;
    border-left-style: solid;
    border-left-width: 2px;
    border-left-color: #727272;
    border-radius: 0px;
    background-color: #F6F6F6;
    font-size: 11px;
    font-weight: 900;
    border-right-style: none;
    border-top-style: none;
    border-bottom-style: none;
    margin-right: 2px
}

.navbar-separator {
    border-color: #727272;
    margin-top: 24px;
    margin-left: 16px;
    margin-right: 16px;
    border-left: 2px solid #727272;
}

.required-label {
    font-weight: 800;
    font-size: 12px;
    margin-bottom: 6px;
    margin-left: 4px
}



.arrow {
    display: none !important;
}


.timepicker-input {
    font-size: 12px;
    text-align: center;
    height: 24px;
    padding: 0px 0px;
    border-radius: 2px;
    border-color: #727272;
    width: 60px !important;
    border-width: 2px;
    font-weight: 600;
    color: #F2F2F2;
    margin-left: 22px !important;
    background-color: #727272;
}

.popover-header {
    background-color: #f4f4f4;
}

.timepicker-input.form-control[readonly] {
    background-color: #727272 !important;
}

.timepicker-input.form-control[readonly]:focus {
    background-color: white !important;
}

.list-action-cell {
    overflow: unset !important;
    white-space: nowrap !important;
}

.small-nav {
    position: fixed;
    right: 0px;
    top: 70px;
    left: 0px;
    height: 70px;
    z-index: 99 !important;
    background-color: #00BF71;
}

.small-nav ul {
    padding: 0px;
    height: 70px;
    list-style: none;
}

.small-nav li {
    float: left;
    width: 49%;
}

.small-nav-separator {
    background-color: white;
    width: 2px !important;
    height: 100%;
}


.small-nav a {
    padding: 14px 42px;
}

.required-input-error {
    border-style: solid;
    border-width: 2px;
    border-color: #E04171;
}

.small-nav-span {
    margin-left: 0px !important;
}

.navbar-custom .topnav-menu .nav-link {
    line-height: 0px !important;
    margin-top: 24px;
    padding: 3px 12px !important;
    border-style: solid;
    border-width: 2px;
    border-color: #727272;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 900;
}

.navbar-custom .topnav-menu .nav-link:hover {
    background-color: #ECECEC;
}

.navbar-custom .topnav-menu .nav-link[aria-expanded="true"] {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.dropdown-menu.show {
    font-size: 12px;
    width: 100%;
    text-align: center;
    padding: 0px;
    border-radius: 6px;
    border-width: 2px;
    border-color: #727272;
    border-top-width: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    background-color: #ECECEC;
}

.notification-list .profile-dropdown .notify-item {
    color: #B1B1B1;
    padding: 3px 20px !important;
    background-color: #ECECEC;
}

.notification-list .profile-dropdown .notify-item:hover {
    color: #727272;
    padding: 3px 20px !important;
}

.dropdown-item.notify-item {
    font-weight: 900 !important;
}

.dropdown-menu.show {
    transform: translate3d(0px, 0px, 0px) !important;
}

.guid-header {
    background-color: #F6F6F6;
    padding: 8px 15px;
    border-style: solid;
    border-width: 0px;
    border-radius: 6px;
    color: #727272;
    font-weight: 900;
}




@-moz-document url-prefix() { 
    .remove-pdf-preview {
        position: initial;
    }

    .dropzone {
        text-align: center !important;
    }
    
}

@supports (-ms-ime-align:auto) {
    .remove-pdf-preview {
        position: initial;
    }

    .sidebar-list-span {
        font-size: 11px !important;
    }
}

@media screen and (min-width:0\0) {
    .sidebar-list-span {
        font-size: 11px !important;
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.time-wrapper {
    display: inline-flex;
    margin-left: 20px;
    background-color: #727272;
}
.time-input {
    width: 40px;
    height: 24px;
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
    margin: 0px !important;
    background-color: #727272;
    color: white;
    font-weight: 600;
    font-size: 12px;
    border-style: none;
}

.time-divider {
    margin-left: 0px !important;
    color: white;
}

.time-wrapper:focus-within {
    background-color: white !important;
    color: #495057;
}

.time-wrapper:focus-within input {
    background-color: white !important;
    color: #495057;
}

.time-wrapper:focus-within span {
    background-color: white !important;
    color: #495057;
}

.invalid-time.time-wrapper {
    border-style: solid;
    border-color: #e04171;
    border-width: 2px;
}

.invalid-time input {
    height: 21px !important;
}

.invalid-time span {
    line-height: normal;
}

// .time-wrapper input:focus ~ #minutes-input {
//     background-color: white !important;
//     color: #495057;
// }


